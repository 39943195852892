<template>
  <div class="pay-page">
    <v-sheet color="grey" class="fullwidth">
      <v-card flat class="border-color--grey border-1">
        <v-card-title>
          <v-btn class="text-none" text outlined @click="$router.push({name: 'default-content'})">Dashboard</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="text-none ml-1" text outlined @click="$router.push({name: 'subscriptions'})">Purchase More Addons</v-btn>
        </v-card-title>
        <v-card-text class="px-5">
          <v-row dense>
            <v-col md="12">
              <empty style="height: 500px;" icon="mdi-emoticon-wink-outline"
                     headline="Thank You for your purchased!"
></empty>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-sheet>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Summary",
  data: () => ({
    processing: false
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('subscription', ['main_selected', 'addons_selected', 'totals']),
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
 

@include pagePadding('.pay-page');
</style>